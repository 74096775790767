



































import { Component, Vue } from "vue-property-decorator";
import ContentCard from "@/components/content-card/ContentCard.vue";

@Component({
    components: {
        ContentCard,
    },
})
export default class AboutUs extends Vue {}
